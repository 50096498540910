import React, { useState, useMemo } from 'react'
import { useSetRecoilState } from 'recoil'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { Button, Table } from '/src/components/common'
import { Loading } from '/src/components/loading'
import { getCart, cartState } from '/src/hooks/useCart'
import { useProductFavourite } from '/src/hooks'
import { postRequest } from '/src/httpClient'

import { QuantityInputElement } from '/src/components/commerce/form/elements'

import './product_favourite_table.scss'

const buildTableColumns = () => {
  return [
    {
      Header: 'Id',
      accessor: 'product_id'
    },
    {
      Header: 'Quantity',
      accessor: 'quantity'
    },
    {
      Header: 'Product',
      accessor: 'product',
      sort: 'asc'
    },
    {
      Header: 'Weight',
      accessor: 'weight'
    },
    {
      Header: 'Remove favourite',
      accessor: 'remove'
    },
  ]
}

const buildTableData = (favourites, setFavourites, setSelectedQuantity) => {
  const removeFavourite = (id) => {
    setFavourites(values => values.filter(value => value.id !== id))
  }
  const onQuantityChange = (id, quantity) => {
    setSelectedQuantity(values => {
      const index = values.findIndex(value => value.id === id)
      if (index === -1) {
        return [...values, { id, quantity }]
      }
      values[index].quantity = quantity
      return values
    })
  }

  return favourites.map(({ id, drupal_id, product_id, title, reference, weight, attributes, type }) => {
    return {
      id: drupal_id,
      type: type,
      product_id: product_id,
      quantity: <QuantityInputElementWrapper id={drupal_id} onQuantityChange={onQuantityChange} />,
      product: <>
        <div>{reference}</div>
        <div>{title}</div>
      </>,
      weight: weight && weight.number ? (
        <div>{parseFloat(weight.number).toFixed(2)} {weight.unit}</div>
      ) : <></>,
      // attributes: <RenderAttributes attributes={attributes} />,
      remove: <Button variant="primary" name="remove" onClick={() => removeFavourite(id)}>
        <FontAwesomeIcon icon={faClose} />
      </Button>,
    }
  })
}

const QuantityInputElementWrapper = ({ id, onQuantityChange }) => {
  const [quantity, setQuantity] = useState(1)

  return <QuantityInputElement
    elementName={`quantity_${id}`}
    quantity={quantity} 
    setQuantity={(value) => {
      onQuantityChange(id, value(quantity))
      setQuantity(value)
    }}
    setValue={() => {}}
    register={{
      value: quantity,
    }} />
}

const RenderAttributes = ({ attributes }) => {
  return attributes.map(({ name, value }) => {
    return <div key={name}>{value}</div>
  })
}

export const ProductFavouriteTable = () => {
  const setCart = useSetRecoilState(cartState)
  const [favourites, setFavourites] = useProductFavourite()
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedQuantity, setSelectedQuantity] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const hasFavourites = useMemo(() => favourites && favourites.length > 0, [favourites])

  const columns = useMemo(() => buildTableColumns(), [])
  const data = useMemo(() => buildTableData(favourites, setFavourites, setSelectedQuantity), [favourites])

  if (!hasFavourites) {
    return <>No favourites found.</>
  }

  const addToCartHandler = () => {
    if (!selectedRows.length) return
    setIsLoading(true)

    postRequest('/api/cart/add', {
      cart: {
        data: selectedRows.map(({ id, type }) => {
          const quantity = selectedQuantity.find(value => value.id === id)
    
          return {
            type: transformInternalType(type),
            id: id,
            meta: {
              quantity: quantity ? quantity.quantity : 1,
              combine: true
            }
          }
        })
      }
    }).then(() => getCart().then(cart => {
      setCart(cart)
      setIsLoading(false)
    })).catch(() => setIsLoading(false))
  }

  return (
    <Row>
      <Col sm={12} className="mb-3">
        <Button 
          variant="primary" 
          onClick={addToCartHandler}
          disabled={isLoading ? 'disabled' : false}>
            Add Selected To Cart
            {isLoading ? <Loading /> : null}
          </Button>
      </Col>
      <Col sm={12}>
        <Table 
          className="product-favourites-table" 
          columns={columns} 
          data={data}
          sorting={[{ id: 'title', desc: false }]}
          onSelectRow={(selected) => setSelectedRows(selected)} />
      </Col>
      <Col sm={12} className="mt-3">
        <Button 
          variant="primary" 
          onClick={addToCartHandler}
          disabled={isLoading ? 'disabled' : false}>
            Add Selected To Cart
            {isLoading ? <Loading /> : null}
          </Button>
      </Col>
    </Row>
  )
}

const transformInternalType = (value) => {
  return value.replaceAll('_', '-')
}